<template>
  <div class="pt-4 px-3 p-3">
    <div id="filterBar" class="flex flex-col border p-4 rounded-lg bg-white">
      <div class="flex flex-row">
        <div class="flex w-full">
          <input
            :placeholder="filters.elements.search.options.hint"
            v-model="searchText"
            type="search"
            @change="searchChild"
            class="border-t text-gray-700 text-xs border-b border-l rounded-l-md w-full focus:outline-none py-1 px-4"
          />
          <button
            class="bg-white rounded-r-md text-green-500 border-t border-r border-b hover:opacity-75 px-3 focus:outline-none"
          >
            <i class="py-1 material-icons">search</i>
          </button>
        </div>
        <div class="">
          <button
            @click="showMore = !showMore"
            class="text-white hover:bg-gray-400 rounded-full bg-green-600 flex items-center focus:outline-none p-2 ml-2"
          >
            <i v-if="showMore" class="material-icons">keyboard_arrow_up</i>
            <i v-else class="material-icons">keyboard_arrow_down</i>
          </button>
        </div>
      </div>
      <div class="flex flex-col" v-if="showMore">
        <div class="flex flex-row space-x-3 justify-center flex-wrap">
          <div
            v-for="(item, index) in filters.elements.champs"
            :key="index"
            class="w-56 mb-4 mt-6"
            v-show="isShown(item)"
          >
            <div
              class="border h-10 hover:border-green-500 focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
            >
              <div
                class="-mt-4 absolute tracking-wider px-1 capitalize text-xs"
              >
                <p>
                  <label for="seller" class="bg-white text-gray-700 px-1"
                    >{{ item.label }}
                    <span :class="$colors.required">*</span></label
                  >
                </p>
              </div>
              <!-- <select v-if="item.type == 'select'" :name="item.key" :id="item.key" autocomplete="type" v-model="filter[item.key]"
                        class="py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white">
                            <option  selected disabled :value="null">{{item.key}}</option>
                            <option v-for="o in item.values" :key="o.key || o" :value="o._id || o">{{ o.name ||o.username || o }}</option>
                        </select>    -->
              <div v-if="item.type == 'select'">
                <div v-if="item.name">
                  <v-select
                    @search="searchMore($event, item.key)"
                    :label="item.name"
                    v-model="filter[item.key]"
                    :options="item.values"
                  ></v-select>
                </div>
                <div v-else>
                  <v-select
                    v-model="filter[item.key]"
                    :options="item.values"
                  ></v-select>
                </div>
              </div>
              <div v-if="item.type == 'number'">
                <input
                  :max="item.max"
                  :min="item.min"
                  :placeholder="item.name"
                  :step="item.step"
                  v-model="filter[item.key]"
                  type="number"
                  :name="item.key"
                  :id="item.key"
                  class="py-2 text-xs px-2 outline-none block h-full w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-center space-x-2 mt-1">
          <button
            @click="ClearFilters"
            class="text-green-500 rounded-md mx-1 border border-gray-100 bg-gray-300 hover:bg-green-600 w-1/6 hover:text-white py-2 px-4 focus:outline-none"
          >
            Reset
          </button>
          <button
            @click="filtrer"
            class="rounded-md mx-1 border border-gray-100 bg-green-600 hover:bg-green-600 text-white py-2 px-4 w-1/6 focus:outline-none"
          >
            Filtrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filters: { required: true, type: Object },
    name: { type: String, default: "" },
    pFiltrer: { type: Function },
    pSearch: { type: Function },
    reset: { type: Function },
  },
  data() {
    return {
      showMore: false,
      searchText: "",
      filter: {},
    };
  },
  mounted() {},
  methods: {
    isShown(item) {
      let shown =
        !item.show ||
        (item.show && this.filter[item.show.key] == item.show.equal);
      if (!shown) this.$set(this.filter, item.key, null);
      return shown;
    },
    searc() {
      //
    },
    /*********************************************************************************** */
    async searchChild() {
      this.$emit("search", this.searchText);
    },
    async filtrer() {
      this.$emit("filtrer", this.filter);
    },
    async ClearFilters(val) {
      this.$emit("reset", val);
    },
    searchMore: function (value, event) {
      if (event == "role") this.$emit("searchRoles", value, event);
    },
  },
};
</script>

<style></style>
